// Importation of the logo in the footer
import LogoResolys from './../Images/svg/Logo-rezolys.svg'

const FooterContent = {
    // The logo
    logo: LogoResolys,
    description: "Rezolys, votre partenaire de confiance en intégration de solutions systèmes et réseaux, en cybersécurité et en protection des infrastructures informatiques d'entreprise.",
    // Contact info
    contact: {
        phone: "Coming soon",
        mail: "contact@rezolys.com",
        location: "Logement 449 Cité Ampefiloha 3ème etage, Antananarivo 101",
        newLocation: {
            tananarive: "Logement 449 Cité Ampefiloha 3ème etage, Antananarivo 101",
            toamasina: "Toamasina 501, Coming Soon"
        }
    },
    // The copyright text
    copyright: "Rezolys, Copyright 2024"
}

export default FooterContent
