import logoImgOnly from "./../Images/svg/Icon-rezolys.svg"

// The join image, just change the link to the correct one
import joinImage from './../Images/other/ImageUtilie-01.jpg'


const HomeContent = {
    // The title of the pages
    pageTitle: "Rezolys: The network within your reach",

    // Welcome section
    welcome: {
        title: "CYBERSECURITE, AUDIT, INFRASTRUCTURES RESEAUX",
        name: "Rezolys",
        logo: logoImgOnly,
        slogant: "The network within your reach",
        subtext: "Bienvenue chez Rezolys, votre partenaire de confiance en intégration de solutions",
        // This is the text in the animated section, you can add as many paragraph, it's in this form ['paragraph 1','paragraph 2','paragraph n']
        animatedText: [
            "Systèmes et réseaux",
            "Cybersécurité",
            "Protection des infrastructures informatique",
            "Datacenter et virtualisation des infrastructures informatique",
            "Intégration de solutions téléphonie"
        ]
    },

    // The service Section
    service: {
        title: "Nos Services",
        subtitle: "Des Solutions Personnalisées pour Protéger et Optimiser vos Infrastructures",
    },
    rejoindre: {
        title: "Rejoignez l'aventure Rezolys",
        image: joinImage,
        // You can add as many paragraph as possible, it's in this form ['paragraph 1','paragraph 2','paragraph n']
        paragraph: [
            "Rezolys, votre partenaire de confiance en intégration de solutions systèmes et réseaux, se distingue également par son expertise en cybersécurité et en protection des infrastructures informatiques d'entreprise. Dans un environnement où la sécurité est devenue une priorité absolue, nous mettons notre savoir-faire au service de vos besoins pour garantir la stabilité et la sécurité de vos systèmes.",
            "Notre rôle en tant qu'intégrateur de solutions et de services va au-delà de la simple mise en œuvre technique. Nous accompagnons nos clients dans chaque étape de l’intégration de leur infrastructure informatique et télécommunication, en assurant une prise en charge complète, de la conception à l’installation, afin de garantir des solutions performantes, évolutives et adaptées à leur environnement spécifique.",
            "Aujourd’hui, face à la montée des cybermenaces, il est devenu indispensable de protéger votre entreprise contre les attaques et de renforcer la sécurité de vos infrastructures. C'est pourquoi notre approche inclut une surveillance continue et des solutions proactives pour anticiper les risques, assurant ainsi la résilience et la pérennité de votre système informatique dans ce climat de plus en plus complexe."
        ]
    },

    // The final section
    outcome: {
        title: "Rezolys - The network within you reach",
        description: "Faites confiance à Rezolys, votre partenaire en matière de sécurité informatique, de cybersécurité et de protection des infrastructures informatique d'entreprise."
    }
}

export default HomeContent