import Navbar from "../Components/Navbar"
import Footer from "../Components/Footer"
import { motion } from "framer-motion"
import { fadeIn } from "../Components/variants"
import { CardMedia, Container, Grid2 } from "@mui/material"
import './../Styles/Home.css'
import AproposContent from "../Contents/Apropos"
import { useState } from "react"

const Apropos = () => {

    useState(() => {
        document.title = AproposContent.pageTitle
        window.scrollTo(0,0)
    }, [])

    return (
        <>
            <Navbar />
            <Container maxWidth="xxl">
                <div
                    style={{
                        minHeight: 'calc(100vh - 200px)',
                        marginTop: '150px',
                        display: "flex",
                        alignItems: 'center'
                    }}
                >
                    <Container maxWidth="xxl" sx={{ mt: 4, mb: 0, marginTop: "-50px" }}>
                        <Grid2 container spacing={2}>
                            <Grid2 size={1}></Grid2>
                            <Grid2 size={5} className="ToFullSize" sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <motion.div
                                    variants={fadeIn("right", 0.2)}
                                    initial="hidden"
                                    whileInView={"show"}
                                    viewport={{once: false, amount: 0.7}}
                                    style={{
                                        width: "100%",
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <CardMedia
                                        component="img"
                                        alt="Image"
                                        image={AproposContent['image']}
                                        sx={{ width: "100%" }}
                                    />
                                </motion.div>
                            </Grid2>
                            <Grid2 size={5} className="ToFullSize" sx={{ marginBottom: "25px", flexDirection: "column", display: "flex", justifyContent: "center" }}>
                                <div
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center'
                                    }}
                                >
                                    <motion.h1
                                        className="font-1-5vw"
                                        variants={fadeIn("left", 0.2)}
                                        initial="hidden"
                                        whileInView={"show"}
                                        viewport={{once: false, amount: 0.7}}
                                        style={{ width: '80%' }}
                                    >
                                        {AproposContent['title']}
                                    </motion.h1>
                                    {(AproposContent.description.map((row, index) => {
                                        return (
                                            <motion.p
                                                key={index}
                                                className="font-normal"
                                                variants={fadeIn("left", 0.2)}
                                                initial="hidden"
                                                whileInView={"show"}
                                                viewport={{once: false, amount: 0.7}}
                                                style={{ width: '80%' }}
                                            >
                                                {row}
                                            </motion.p>
                                        )
                                    }))}
                                </div>
                            </Grid2>
                            <Grid2 size={1}></Grid2>
                        </Grid2>
                    </Container>
                </div>
            </Container>
            <Footer />
        </>
    )
}

export default Apropos