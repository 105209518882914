///////// Library Importation ///////

// React Hook 
import { useContext, useEffect, useState } from "react"

// MUI Component
import { CardMedia, Container, Grid2 } from "@mui/material"

// Router parameters
import { useParams } from "react-router-dom"

// Personalised Component Importation 
import Navbar from "../Components/Navbar"
import Footer from "./../Components/Footer"

// Logo image only
import logoImgOnly from "./../Images/svg/Icon-rezolys.svg"

// Personalised animation
import { motion } from "framer-motion"
import { fadeIn } from "../Components/variants"

// Personalised Style
import './../Styles/Home.css'

// Context CSV for the data
import { CSVContext } from "../Contexts/CSVContext"

// 404 page module
import NoPage from "./noPage"

const Loading = () => {
    return (
        <>
            <motion.div
                variants={fadeIn("", 0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{once: false, amount: 0.7}}
                style={{
                    fontSize: '8vw',
                    position: "fixed",
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    fontFamily: 'cursive',
                    letterSpacing: '3.5px',
                    fontWeight: '800',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                Page is loading...
            </motion.div>
        </>
    )
}

const View = () => {
    // Data CSV Context
    const csvData = useContext(CSVContext)

    // The parameter in the route
    const { request } = useParams()

    // Set all the state
    const [data, setData] = useState(null)
    const [loaded, setLoaded] = useState(false)
    const [lastPage, setLastPage] = useState(null)

    // Function that change the state value
    const settingData = (getData) => {
        setData(getData)
    }
    const setTheLastPage = (getPage) => {
        setLastPage(getPage)
    }
    const isLoaded = () => {
        setLoaded(true)
    }
    
    // Load the requested data
    useEffect(() => {
        if (!loaded || lastPage !== request) {
            window.scrollTo(0,0)
            if (csvData) {
                const rows = csvData.map((row, index) => {
                    var img = null
                    try {
                        img = require("./../Images/other/"+row['Featured image'])
                    } catch (err) {}
                    row['imageFile'] = img
                    return {index: index, data: row.Link, all: row}
                })
                const index = rows.findIndex(obj => obj.data === request)
                if (index !== -1) {
                    document.title = "Rezolys Service: "+rows[index].all['Name']
                    settingData(rows[index].all)
                } else {
                    settingData(404)
                }
                isLoaded()
                setTheLastPage(request)
            }
        }
    });


    return (
        <>
            {
                (loaded) ? ((data === 404) ? (<NoPage />) : (<>
                    <>
                    <Navbar />
                    <Container maxWidth="xl" sx={{ mt: 4, mb: 0 }}>
                        <Grid2 
                            container
                            spacing={2} 
                            sx={{marginTop: "1.5vh", padding: "0 0 20vh 0"}}
                            className="ToColumn"
                        >
                            <Grid2 size={12} sx={{textAlign: "center"}}>
                                <motion.h1
                                    variants={fadeIn("left", 0.2)}
                                    initial="hidden"
                                    whileInView={"show"}
                                    viewport={{once: false, amount: 0.7}}
                                    className="font-2vw"
                                    style={{
                                        fontFamily: 'cursive',
                                        letterSpacing: '3.5px',
                                        fontWeight: 'bold',
                                        marginTop: "5vh"
                                    }}
                                >
                                    {data['Name']}
                                </motion.h1>
                            </Grid2>
                            <Grid2 size={6} className="ToFullSize">
                                <motion.div 
                                    variants={fadeIn("right", 0.2)}
                                    initial="hidden"
                                    whileInView={"show"}
                                    viewport={{once: false, amount: 0.7}}
                                >
                                    <CardMedia
                                        component="img"
                                        alt="Image File"
                                        image={data['imageFile']}
                                        sx={{ width: "90%", border: "2px solid white", borderRadius: '15px' }}
                                    />
                                </motion.div>
                            </Grid2>
                            <Grid2 size={6} className="ToFullSize" sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center'
                            }}>
                                <motion.h2
                                variants={fadeIn("left", 0.2)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                                className="font-1-5vw"
                                >
                                    {data['Welcome word title']}
                                </motion.h2>
                                <motion.p
                                    variants={fadeIn("left", 0.2)}
                                    initial="hidden"
                                    whileInView={"show"}
                                    viewport={{once: false, amount: 0.7}}
                                    className="font-1-5vw"
                                >
                                    {data['Welcome word']}
                                </motion.p>
                            </Grid2>
                        </Grid2>
                    </Container>
                    <div style={{ 
                        position: "relative",
                        marginTop: "-3vh",
                        clipPath: "polygon(23% 0, 75% 0, 100% 2vh, 100% 100%, 0 100%, 0 2vh)",
                        background: "white",
                        left: "0",
                        width: "100%",
                        color: "black",
                        paddingTop: "10vh",
                        paddingBottom: "18vh"
                    }}>
                        <Container maxWidth="xl" sx={{ mt: 4, mb: 0 }}>
                            <Grid2 
                                container
                                spacing={2} 
                                sx={{marginTop: "1.5vh", padding: "0 0 20vh 0"}}
                                className="ToColumn"
                            >
                                <Grid2 size={12} sx={{textAlign: "center"}}>
                                    <motion.h2
                                        variants={fadeIn("left", 0.2)}
                                        initial="hidden"
                                        whileInView={"show"}
                                        viewport={{once: false, amount: 0.7}}
                                        className="font-2-5vw"
                                    >
                                        {data['Main mission title']}
                                    </motion.h2>
                                </Grid2>
                                <Grid2 size={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <motion.p
                                        className="font-1-5vw ToFullSize"
                                        variants={fadeIn("left", 0.2)}
                                        initial="hidden"
                                        whileInView={"show"}
                                        viewport={{once: false, amount: 0.7}}
                                        style={{ width: '50%' }}
                                    >
                                        {data['Main mission']}
                                    </motion.p>
                                </Grid2>
                            </Grid2>
                        </Container>
                    </div>
                    <div style={{ 
                        position: "relative",
                        clipPath: "polygon(63% 0, 73% 2vh, 100% 2vh, 100% 100%, 0 100%, 0 0)",
                        background: "rgb(19 14 33)",
                        left: "0",
                        width: "100%",
                        marginTop: "-5vh",
                        paddingTop: "10vh",
                        paddingBottom: "18vh"
                    }}>
                        <Container maxWidth="xl" sx={{ mt: 4, mb: 0 }}>
                            <Grid2 container spacing={2} className="ToFullSize" sx={{marginTop: "1.5vh", padding: "0 0 20vh 0"}}>
                                <Grid2 size={12}>
                                    <motion.h1
                                    variants={fadeIn("up", 0.2)}
                                    initial="hidden"
                                    whileInView={"show"}
                                    viewport={{once: false, amount: 0.7}}
                                    className="font-2-5vw" 
                                    style={{textAlign:"center"}}>
                                        Plus de détails
                                    </motion.h1>
                                </Grid2>
                                <Grid2 size={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <motion.p
                                        className="font-1-5vw ToFullSize"
                                        variants={fadeIn("left", 0.2)}
                                        initial="hidden"
                                        whileInView={"show"}
                                        viewport={{once: false, amount: 0.7}}
                                        style={{ width: '50%' }}
                                    >
                                        {data['Project details']}
                                    </motion.p>
                                </Grid2>
                            </Grid2>
                        </Container>
                    </div>
                    <div style={{ 
                        position: "relative",
                        marginTop: "-3vh",
                        clipPath: "polygon(23% 0, 75% 0, 100% 2vh, 100% 100%, 0 100%, 0 2vh)",
                        background: "white",
                        left: "0",
                        width: "100%",
                        paddingTop: "18vh",
                        paddingBottom: "18vh"
                    }}>
                        <Container maxWidth="xl" sx={{ mt: 4, mb: 0, marginTop: "-50px", color: "black" }}>
                            <Grid2 container spacing={2}>
                                <Grid2 size={6} className="ToFullSize" sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <motion.div
                                        variants={fadeIn("left", 0.2)}
                                        initial="hidden"
                                        whileInView={"show"}
                                        viewport={{once: false, amount: 0.7}}
                                        style={{
                                            width: "100%",
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <CardMedia
                                            component="img"
                                            alt="Image"
                                            image={logoImgOnly}
                                            sx={{ width: "95%", border: "2px solid white", borderRadius: '15px' }}
                                        />
                                    </motion.div>
                                </Grid2>
                                <Grid2 size={6} className="ToFullSize" sx={{ marginBottom: "25px", flexDirection: "column", display: "flex", justifyContent: "center" }}>
                                    <motion.p
                                        className="font-1-5vw ToFullSize"
                                        variants={fadeIn("left", 0.2)}
                                        initial="hidden"
                                        whileInView={"show"}
                                        viewport={{once: false, amount: 0.7}}
                                        style={{ width: '80%' }}
                                    >
                                        {data['Project summary']}
                                    </motion.p>
                                </Grid2>
                            </Grid2>
                        </Container>
                    </div>
                    <Footer />
                </>
                </>)  ) : (<Loading />)
            }
            
        </>
    )
}

export default View