///// Importation /////

// React Component
import React, { createContext, useState, useEffect } from 'react';

// The Library for reading CSV file
import Papa from 'papaparse';

// The database files
import file from './../Databases/Resolys-database.csv'

// Create a context
export const CSVContext = createContext();

// Composant Provider
export const CSVProvider = ({ children }) => {
  const [csvData, setCsvData] = useState(null);

  // Charge the CSV data
  useEffect(() => {
    Papa.parse( file, {
      download: true,
      header: true,
      encoding: "UTF-8",
      complete: (result) => {
        setCsvData(result.data);
      },
    });
  }, []);

  return (
    <CSVContext.Provider value={csvData}>
      {children}
    </CSVContext.Provider>
  );
};
