///////// Library Importation ///////

// React Hook 
import React, { useContext, useEffect, useRef, useState } from "react";

// Personalised Component Importation 
import Navbar from "../Components/Navbar"
import Footer from "./../Components/Footer";

// Material UI Component Importation
import Container from "@mui/material/Container";
import Grid2 from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { Card, CardActions, CardContent, CardMedia } from "@mui/material";
// Material UI Icon Importation
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import NetworkPingOutlinedIcon from '@mui/icons-material/NetworkPingOutlined';

// Animation Motion Component and Personalised Animation Importation 
import { motion } from "framer-motion";
import { fadeIn } from "../Components/variants";

// Personalised Style Sheet Importation
import "./../Styles/Home.css"

// Homepage Text and Icon, Image Content
import HomeContent from "../Contents/Home";
import NavbarContent from "../Contents/Navbar";

import LinkedBtn from "../Components/LinkedBtn";

// CSV Context that contain the data
import { CSVContext } from "../Contexts/CSVContext";



// Personalised Image Card 1 props => title, description, destination (Link in learn more)
const ImgCard = (props) => {
    return (
        <Card sx={{ borderRadius: "25px", height: "100% ", display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <CardMedia
                component="img"
                alt="Image"
                image={props.image}
                sx={{ height: "25vh" }}
            />
            <CardContent
                sx={{
                    
                }}
            >
                <Typography gutterBottom variant="h4" component="div" className="font-1-5vw">
                    {props.title}
                </Typography>
                <Typography variant="h6" className="font-normal" sx={{ color: 'text.secondary' }}>
                    {props.description}
                </Typography>
            </CardContent>
            <CardActions>
                <LinkedBtn fullWidth={true} endIcon={<ArrowForwardIcon />}  className="font-normal" text="Voir plus" destination={"/pages/"+props.destination} />
            </CardActions>
        </Card>
    )
}


// The Home component to export
const Home = () => {
    
    const csvData = useContext(CSVContext)

    const [loaded, setLoaded] = useState(false)
    const [loadedCSV, setLoadedCSV] = useState(false)
    const [data, setData] = useState(null)

    const anim = useRef()
    const animName = useRef()
    const animLogo = useRef()

    const settingData = (getData) => {
        setData(getData)
    }

    const setLoadedCSVData = () => {
        setLoadedCSV(true)
    }

    const setLoadedPage = () =>{
        setLoaded(true)
    }

    // The animation of the logo in the welcome section
    const animateLogo = (i = 0) => {
        try {
            clearTimeout(animLogo.current)
            if (i === 0) {
                document.getElementById("logoToAnimate").style.translate = "0 -5px"
                i++
                animLogo.current = setTimeout(animateLogo.bind(this, i), 8000)
            } else if (i === 1) {
                document.getElementById("logoToAnimate").style.translate = "0 0"
                i++
                animLogo.current = setTimeout(animateLogo.bind(this, i), 8000)
            } else {
                document.getElementById("logoToAnimate").style.translate = "0 5px"
                animLogo.current = setTimeout(animateLogo.bind(this, 0), 8000)
            }
        } catch (err) {clearTimeout(animLogo.current)}
    }

    // The animation of the Name
    const animateName = (i = 0, reverse = false) => {
        try {
            clearTimeout(animName.current)
            if (reverse){
                if (i > 0) {
                    document.getElementById("nameDiv").lastChild.remove()
                    i--
                    animName.current = setTimeout(animateName.bind(this, i, true), 500)
                } else {
                    animName.current = setTimeout(animateName.bind(this, 0), 500)
                }
            } else {
                if (i < HomeContent.welcome.name.length) {
                    const myNode = document.createElement("div")
                    const myTextNode = document.createTextNode(HomeContent.welcome.name[i].toUpperCase())
                    myNode.appendChild(myTextNode)
                    document.getElementById("nameDiv").appendChild(myNode)
                    i++
                    animName.current = setTimeout(animateName.bind(this, i), 300)
                } else {
                    animName.current = setTimeout(animateName.bind(this, i, true), 5000)
                }
            }
        } catch (err) {clearTimeout(animName.current)}
    }

    // The typing animation card
    const animateCard = (text = '', i = 0, reverse = false, y = 0) => {
        try {
            clearTimeout(anim.current)
            if (reverse) {
                if (i >= 0){
                    
                    text = text.slice(0, i)
                    document.getElementById("animatedText").innerText = text
                    i--
                    anim.current = setTimeout(animateCard.bind(this, text, i, true, y), 100)
                } else {
                    if (y < (HomeContent.welcome.animatedText.length -1)){
                        y++
                        anim.current = setTimeout(animateCard.bind(this, text, 0, false, y), 1000)
                    } else {
                        y=0
                        anim.current = setTimeout(animateCard.bind(this, text, 0, false, y), 1000)
                    }
                }
            } else {
                if (i < (HomeContent.welcome.animatedText[y].length)) {
                    text += HomeContent.welcome.animatedText[y][i]
                    document.getElementById("animatedText").innerText = text
                    i++
                    anim.current = setTimeout(animateCard.bind(this, text, i, false, y), 250)
                } else {
                    anim.current = setTimeout(animateCard.bind(this, text, i, true, y), 1000)
                }
            }
        } catch (err) {}
    }


    useEffect(() => {
        document.title = HomeContent.pageTitle
        if (!loaded) {
            window.scrollTo(0,0)
            setLoadedPage()
            anim.current = setTimeout(animateCard, 300)
            animName.current = setTimeout(animateName, 300)
            animLogo.current = setTimeout(animateLogo, 300)
        }
        if (!loadedCSV) {
            if (csvData) {
                const rows = csvData.map((row, index) => {
                    var img = null
                    try {
                        img = require("./../Images/other/"+row['Featured image'])
                    } catch (err) {}
                    row['imageFile'] = img
                    return { 
                        image: img,
                        title: row['Name'],
                        description: row['Project summary'],
                        link: row['Link']
                    }
                })

                settingData(rows)
                setLoadedCSVData()
            }
        }
    })

    return (
        <>
            <Navbar />
            <Container maxWidth="xl" sx={{ mt: 4, mb: 0 }}>
                <Grid2 container spacing={2}>
                    <Grid2 className="ToColumn" size={5} sx={{display: "flex", justifyContent: "center", flexDirection: "column"}}>
                        <Grid2 className="ToFullSize" size={12} sx={{marginBottom: "1.0vh"}}>
                            <motion.div 
                                className="font-normal"
                                variants={fadeIn("down", 0.2)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                            >
                                {HomeContent.welcome.title.toUpperCase()}
                            </motion.div>
                        </Grid2>
                        <Grid2 size={12} sx={{marginBottom: "1vh"}}>
                            <motion.div
                                className="font-4vw"
                                variants={fadeIn("right", 0.2)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                                style={{
                                    fontWeight: 800,
                                    display: "flex"
                                }}
                                
                            >
                                <div style={{
                                    display: "flex"
                                }} id="nameDiv"></div><div style={{
                                    opacity: 0
                                }}>-</div>
                            </motion.div>
                        </Grid2>
                        <Grid2 size={12} sx={{marginBottom: "2vh"}}>
                            <motion.div
                                className="font-2vw"
                                variants={fadeIn("down", 0.2)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                            >
                                {HomeContent.welcome.slogant}
                            </motion.div>
                        </Grid2>
                        <Grid2 size={12} sx={{marginBottom: "1.5vh"}}>
                            <motion.div
                                className="font-1-5vw"
                                variants={fadeIn("up", 0.2)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                            >
                                {HomeContent.welcome.subtext}
                            </motion.div>
                        </Grid2>
                        <Grid2 size={12} sx={{marginBottom: "2vh"}}>
                            <motion.div
                                variants={fadeIn("up", 0.2)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                            >
                                <Card variant="outlined" sx={{ padding: "1vw" }} className="font-normal">
                                    <b> </b>
                                    <b
                                        id="animatedText"
                                        style={{
                                            paddingRight: "2px",
                                            transition: "0.2ms",
                                            borderRight: "solid 2px white"
                                        }}
                                    >   
                                    </b>
                                </Card>
                            </motion.div>
                        </Grid2>
                        <Grid2 size={12} sx={{marginBottom: "2vh"}}>
                            <motion.div
                                variants={fadeIn("up", 0.2)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                            >
                                <LinkedBtn 
                                    variant='contained' 
                                    className='font-normal ToFullSize'
                                    text = {NavbarContent.contact.text}
                                    destination = { NavbarContent.contact.destination }
                                />
                            </motion.div>
                        </Grid2>
                    </Grid2>
                    <Grid2 size={1}></Grid2>
                    <Grid2 className="ToFullSize" size={6} >
                        <motion.div
                            variants={fadeIn("left", 0.2)}
                            initial="hidden"
                            whileInView={"show"}
                            viewport={{once: false, amount: 0.7}}
                        >
                            <CardMedia
                                className="imageLogoBig"
                                component="img"
                                alt="Logo"
                                image={HomeContent.welcome.logo}
                                sx={{ transition: '10s' }}
                                id="logoToAnimate"
                            />
                        </motion.div>
                    </Grid2>
                </Grid2>
            </Container>
            <div style={{ 
                position: "relative",
                clipPath: "polygon(63% 0, 73% 2vh, 100% 2vh, 100% 100%, 0 100%, 0 0)",
                background: "rgb(39 30 63)",
                left: "0",
                width: "100%",
                paddingTop: "2vh",
                paddingBottom: "20vh"
            }}>
                <Container maxWidth="xl" sx={{ mt: 4, mb: 0 }}>
                    <Grid2 container spacing={2} sx={{marginTop: "1.5vh"}}>
                        <Grid2 size={12} sx={{ marginBottom: "25px", marginTop: "10vh" }}>
                            <motion.div
                            variants={fadeIn("up", 0.2)}
                            initial="hidden"
                            whileInView={"show"}
                            viewport={{once: false, amount: 0.7}}
                            style={{ marginBottom: "15px", textAlign: "center", width: "100%" }} className="font-3vw">
                                <NetworkPingOutlinedIcon className="font-2vw" />{HomeContent.service.title}<NetworkPingOutlinedIcon className="font-2vw" />
                            </motion.div>
                            <motion.div
                            variants={fadeIn("up", 0.2)}
                            initial="hidden"
                            whileInView={"show"}
                            viewport={{once: false, amount: 0.7}}
                            className="font-normal" style={{ textAlign: "center", marginBottom: "15px", width: "100%" }}>
                                {HomeContent.service.subtitle}
                            </motion.div>
                        </Grid2>
                        {(data) ? data.map((row, index) => {
                            const pos = (index === 0) ? "right" : (index === 1) ? "up" : "left"
                            
                            if ((data.length%3 === 0 || (data.length - index) >= 3) || (data.length%3 === 1 && (data.length - index) === 2)) {
                                return (
                                    <Grid2 key={index} size={4} className="ToFullSize">
                                        <motion.div
                                            variants={fadeIn(pos, 0.2)}
                                            initial="hidden"
                                            whileInView={"show"}
                                            viewport={{once: false, amount: 0.7}}
                                            style={{height: '100%'}}
                                        >
                                            <ImgCard title={row.title} image={row.image} description={row.description} destination={row.link} />
                                        </motion.div>
                                    </Grid2>
                                )
                            } else if (data.length%3 === 2) {
                                if ((data.length - index) === 2){
                                    return (
                                        <React.Fragment key={index}>
                                            <Grid2 size={2} />
                                            <Grid2 size={4} className="ToFullSize">
                                                <motion.div
                                                    variants={fadeIn(pos, 0.2)}
                                                    initial="hidden"
                                                    whileInView={"show"}
                                                    viewport={{once: false, amount: 0.7}}
                                                    style={{height: '100%'}}
                                                >
                                                    <ImgCard title={row.title} image={row.image} description={row.description} destination={row.link} />
                                                </motion.div>
                                            </Grid2>
                                        </React.Fragment>
                                    )
                                } else {
                                    return (
                                        <React.Fragment key={index}>
                                            <Grid2 size={4} className="ToFullSize">
                                                <motion.div
                                                    variants={fadeIn(pos, 0.2)}
                                                    initial="hidden"
                                                    whileInView={"show"}
                                                    viewport={{once: false, amount: 0.7}}
                                                    style={{height: '100%'}}
                                                >
                                                    <ImgCard title={row.title} image={row.image} description={row.description} destination={row.link} />
                                                </motion.div>
                                            </Grid2>
                                            <Grid2  size={2} />
                                        </React.Fragment>
                                    )
                                }
                            } else {
                                return (
                                    <React.Fragment key={index}>
                                        <Grid2 size={4} />
                                        <Grid2 size={4} className="ToFullSize">
                                            <motion.div
                                                variants={fadeIn(pos, 0.2)}
                                                initial="hidden"
                                                whileInView={"show"}
                                                viewport={{once: false, amount: 0.7}}
                                                style={{height: '100%'}}
                                            >
                                                <ImgCard title={row.title} image={row.image} description={row.description} />
                                            </motion.div>
                                        </Grid2>
                                        <Grid2  size={4} />
                                    </React.Fragment>
                                )
                            }
                        }) : (<></>)}
                    </Grid2>
                </Container>
            </div>
            <div style={{ 
                position: "relative",
                marginTop: "-3vh",
                clipPath: "polygon(23% 0, 75% 0, 100% 2vh, 100% 100%, 0 100%, 0 2vh)",
                background: "white",
                left: "0",
                width: "100%",
                paddingTop: "20vh",
                paddingBottom: "20vh"
            }}>
                <Container maxWidth="xl" sx={{ mt: 4, mb: 0, marginTop: "-50px", color: "black" }}>
                    <Grid2 container spacing={2}>
                        <Grid2 size={1}></Grid2>
                        <Grid2 size={5} className="ToFullSize" sx={{ marginBottom: "25px", flexDirection: "column", display: "flex", justifyContent: "center" }}>
                            <Grid2 container spacing={2}>
                                <Grid2 size={7}>
                                    <Grid2 sx={
                                        {
                                            marginBottom: "5vh"
                                        }
                                    }>
                                        <motion.div
                                        variants={fadeIn("down", 0.2)}
                                        initial="hidden"
                                        whileInView={"show"}
                                        viewport={{once: false, amount: 0.7}}
                                        className="font-2vw">
                                            { HomeContent.rejoindre.title }
                                        </motion.div>
                                    </Grid2>
                                    {
                                        HomeContent.rejoindre.paragraph.map((data, index) => {
                                            return (
                                                <Grid2 key={index} sx={
                                                    {
                                                        marginBottom: "15px"
                                                    }
                                                }>
                                                    <motion.div
                                                    variants={fadeIn("up", 0.2)}
                                                    initial="hidden"
                                                    whileInView={"show"}
                                                    viewport={{once: false, amount: 0.7}}
                                                    className="font-normal">
                                                        {data}
                                                    </motion.div>
                                                </Grid2>
                                            )
                                        })
                                    }
                                </Grid2>
                            </Grid2>
                        </Grid2>
                        <Grid2 size={5} className="ToFullSize" sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <motion.div
                                variants={fadeIn("left", 0.2)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                                style={{
                                    width: "100%",
                                    height: "50vh",
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    alt="Image"
                                    image={HomeContent.rejoindre.image}
                                    sx={{ width: "90%", border: "2px solid white", borderRadius: '15px' }}
                                />
                                {/* <img src={image4} style={{ maxWidth: '100%' }} alt="Texte" /> */}
                            </motion.div>
                        </Grid2>
                        <Grid2 size={1}></Grid2>
                    </Grid2>
                </Container>
            </div>
            <div style={{ 
                position: "relative",
                clipPath: "polygon(63% 0, 73% 2vh, 100% 2vh, 100% 100%, 0 100%, 0 0)",
                background: "rgb(19 14 33)",
                left: "0",
                width: "100%",
                marginTop: "-5vh",
                paddingTop: "20vh",
                paddingBottom: "20vh"
            }}>
                <Container maxWidth="xl" sx={{ mt: 4, mb: 0, marginTop: "-50px" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Grid2 container spacing={2} className="ToFullSize" sx={{ width: "50vw" }}>
                            <Grid2 size={12}>
                                <motion.h1
                                variants={fadeIn("up", 0.2)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                                className="font-2vw" 
                                style={{textAlign:"center"}}>
                                    {HomeContent.outcome.title}
                                </motion.h1>
                            </Grid2>
                            <Grid2 size={12}>
                                <motion.div 
                                variants={fadeIn("up", 0.2)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                                className="font-1-5vw">
                                    {HomeContent.outcome.description}
                                </motion.div>
                            </Grid2>
                        </Grid2>
                    </div>
                </Container>
            </div>
            <Footer />
        </>
    )
}

export default Home